import React from 'react'
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";

const containerStyle = {
	width: "100%",
	height: "520px",
	maxHeight: "520px",
};	 
 
const spot = { lng: -73.63338,lat: -42.93138 };
const center = { lng: -73.63338,lat: -42.93138 };

function Map() {
	return (
		<LoadScript googleMapsApiKey="AIzaSyCy9Kx4UtkxYNsMo09AhZPyDg9K6ktQUiA">
			<GoogleMap
				mapContainerStyle={containerStyle}
				center={center}
				zoom={9}
				mapTypeId={"roadmap"}
			>
				<Marker position={spot} />
			</GoogleMap>
		</LoadScript>
	);
}
export default Map