import React, { useState } from 'react'
import Axios from 'axios'
import { Form, Col, Row, Input, FormGroup, CardBody, Button } from 'reactstrap'

const INITIAL_STATE = {
    loading:false,
    textNombre:'',
    textApellido:'',
    textTelefono:'',
    textEmail:'',
    textMensaje:''
}
function Formulario(){

    const [state, setState] = useState(INITIAL_STATE)
    
    const onChangeText = event => {
        setState({ ...state, [event.target.name]: event.target.value })
    }
    const onEnviarFormulario = (event) => {
        const { textNombre, textApellido, textTelefono, textEmail, textMensaje } = state
        const nombreCompleto = textNombre + ' ' + textApellido
        setState({ loading:true })
        event.preventDefault()
    
        const text = `
			Nombre: ${nombreCompleto} 
            Telefono: ${textTelefono}
			Email: ${textEmail} 
			Mensaje: ${textMensaje}
        `
        const info = {
			to: "pmohana@icebergchile.cl",
			replyTo: textEmail,
			subject:'Formulario Contacto',
			text: text
		}
        Axios.post('https://us-central1-firemailer.cloudfunctions.net/submitContactoFZ', info)
            .then( res => {
                console.log(res)
                console.log(`mensaje enviado: ${res.data.message}`)
                setState({ loading:false })                 
            })
            .catch(error => {
                console.log(error);
            });
            setState(INITIAL_STATE)
    }
    
    const { loading, textNombre, textApellido, textTelefono, textEmail, textMensaje} = state
    const isInvalid = textNombre === '' || textApellido === '' || textTelefono === '' || textEmail ==='' || textMensaje === ''
    return(
            <Form onSubmit={onEnviarFormulario}>
                <CardBody>
                    <Row>
                        <Col md="6">
                            <FormGroup className="label-floating">
                                <label className="control-label">
                                    Nombre
                                </label>
                                <Input
                                    name="textNombre"
                                    value={textNombre}
                                    onChange={onChangeText}
                                    placeholder=""
                                    type="text"
                                />
                            </FormGroup>
                        </Col>
                        <Col md="6">
                            <FormGroup className="label-floating">
                                <label className="control-label">
                                    Apellido
                                </label>
                                <Input
                                    name="textApellido"
                                    value={textApellido}
                                    onChange={onChangeText}
                                    placeholder=""
                                    type="text"
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup className="label-floating">
                                <label className="control-label">
                                    Teléfono
                                </label>
                                <Input
                                    name="textTelefono"
                                    value={textTelefono}
                                    onChange={onChangeText}
                                    placeholder=""
                                    type="text"
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <FormGroup className="label-floating">
                        <label className="control-label">
                            Email 
                        </label>
                        <Input
                            name="textEmail"
                            value={textEmail}
                            onChange={onChangeText}
                            placeholder=""
                            type="email"
                        />
                    </FormGroup>
                    <FormGroup className="label-floating">
                        <label className="control-label">
                            Mensaje
                        </label>
                        <Input
                            id="message"
                            name="textMensaje"
                            value={textMensaje}
                            onChange={onChangeText}
                            placeholder=""
                            type="textarea"
                            rows="6"
                        />
                    </FormGroup>
                    <Row>
                        <Col md="6">
                            <Button type ="submit" color="primary" disabled={isInvalid}>
                                {loading && 
                                    <div className="uil-reload-css reload-small mr-1">
                                        <div />
                                    </div>
                                }
                                Enviar
                            </Button>
                        </Col>
                    </Row>
                </CardBody>
            </Form>
        )
    
}
export default Formulario