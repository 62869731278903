import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import ImgProyecto from '../../assets/images/proyecto.jpg'
function Proyecto(){
  return(
    <>
      <ElProyecto/>
      <Caracteristicas />
    </>
  )
}
export default Proyecto

function ElProyecto(){
  return(
    <div id="reserva" className="section pb-4">
      <Container>
        <Row className="justify-content-center">
          <h2 className="title">El Proyecto</h2>
        </Row>
        <Row className="py-3">
          <Col className="text-center" sm="12" md="6">
            <img style={{maxWidth:'420px', width:'420px'}} src={ImgProyecto} alt="img-proyecto"/>
          </Col>
          <Col md="6">
            <div className="pl-sm-3">
              <h3 className="title mt-0 pt-sm-4">Costa Cisnes</h3>
              <ul className="">
                <li>Cada parcela tiene salida directa al camino principal.</li>
                <li>Energía eléctrica por la puerta de cada parcela.</li>
                <li>Agua de pozo en cada parcela.</li>
                <li>Excelente conexión telefónica e internet.</li>
              </ul>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}
function Caracteristicas(){
  return(
    <div id="caracteristicas">
      <div
          className="features-5 section-image"
          style={{
            backgroundImage:
              "url(" +
              require("assets/images/caracteristicas.jpg") +
              ")",
          }}
        >
          <Container>
            <Row>
              <div className="ml-auto mr-auto">
                <h2 className="title text-center pt-0">
                  Características
                </h2>
              </div>
            </Row>
            <Row>
              <Col className="ml-auto" sm="5">
                <div className="info">
                  <div className="icon">
                    <i aria-hidden={true} className="nc-icon icon-eye" />
                  </div>
                  <h4 className="title">Maravillosa Vista al Mar.</h4>
                </div>
              </Col>
              <Col className="mr-auto" sm="5">
                <div className="info">
                  <div className="icon">
                    <i aria-hidden={true} className="nc-icon icon-road-2" />
                  </div>
                  <h4 className="title">Todas las parcelas con camino directo a la Playa.</h4>
                </div>
              </Col>
            </Row>
            <Row className="bottom-line">
              <Col className="ml-auto" sm="5">
                <div className="info">
                  <div className="icon">
                    <i aria-hidden={true} className="nc-icon icon-land" />
                  </div>
                  <h4 className="title">Lomaje suave y bosques.</h4> 
                </div>
              </Col>
              <Col className="mr-auto" sm="5">
                <div className="info">
                  <div className="icon">
                    <i aria-hidden={true} className="nc-icon icon-bank-statement" />
                  </div>
                  <h4 className="title">Rol propio del Sii.</h4>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
    </div>
  )
}